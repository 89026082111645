
import React from 'react'
import { useGLTF } from '@react-three/drei'

export function Drone( {rotationCamera}) {
  const { nodes, materials } = useGLTF('/dji_fvp_-_sdc_performance_edition.glb')
  console.log(rotationCamera);
  return (
    <group  dispose={null}>
      <group rotation={rotationCamera}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0, 1.017, -0.135]} rotation={[0.015, 0, 0]} scale={3.531}>
            <group rotation={[-Math.PI / 2, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_5.geometry}
                material={materials.M_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_6.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_7.geometry}
                material={materials.Plastic_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_8.geometry}
                material={materials.Plastic_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_9.geometry}
                material={materials.Metall_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_10.geometry}
                material={materials['Material.009']}
              />
            </group>
            <group rotation={[0.416, 0.015, 0.076]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_16.geometry}
                material={materials.Grey_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_17.geometry}
                material={materials.Grey_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_18.geometry}
                material={materials['Material.005']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_19.geometry}
                material={materials.Grey_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_20.geometry}
                material={materials.Plastic_D1}
              />
            </group>
            <group rotation={[0.502, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_22.geometry}
                material={materials.Rubber_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_23.geometry}
                material={materials.Rubber_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_24.geometry}
                material={materials.Rubber_D1}
              />
            </group>
            <group rotation={[0.446, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_26.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_27.geometry}
                material={materials.Rubber_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_28.geometry}
                material={materials.Plastic_D2}
              />
            </group>
            <group rotation={[0.622, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_30.geometry}
                material={materials.Grey_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_31.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_32.geometry}
                material={materials.Rubber_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_33.geometry}
                material={materials.Plastic_L2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_34.geometry}
                material={materials.Plastic_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_35.geometry}
                material={materials.Plastic_Base}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_36.geometry}
                material={materials.Plastic_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_37.geometry}
                material={materials['Material.004']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_38.geometry}
                material={materials['Material.013']}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_40.geometry}
                material={materials.M_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_41.geometry}
                material={materials.Grey_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_42.geometry}
                material={materials.Plastic_Base}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_44.geometry}
                material={materials.Grey_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_45.geometry}
                material={materials.Grey_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_46.geometry}
                material={materials['Material.005']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_47.geometry}
                material={materials.Grey_D2}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_49.geometry}
                material={materials.Grey_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_50.geometry}
                material={materials.CARBONE}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_56.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_57.geometry}
                material={materials.Plastic_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_58.geometry}
                material={materials.Plastic_Base}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_60.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_61.geometry}
                material={materials.Rubber_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_62.geometry}
                material={materials.Plastic_L1}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_64.geometry}
                material={materials.M_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_65.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_66.geometry}
                material={materials.Plastic_Base}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_70.geometry}
                material={materials.Mettal_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_71.geometry}
                material={materials.Metall_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_72.geometry}
                material={materials.Metall_D1}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_74.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_75.geometry}
                material={materials.Plastic_L1}
              />
            </group>
            <group rotation={[-Math.PI / 2, 0, 0]} scale={[0.316, 0.283, 0.283]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_77.geometry}
                material={materials.Plastic_Base}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_78.geometry}
                material={materials.Metall_D2}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_80.geometry}
                material={materials.Grey_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_81.geometry}
                material={materials.Grey_D2}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_83.geometry}
                material={materials.Grey_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_84.geometry}
                material={materials.Grey_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_85.geometry}
                material={materials.Grey_D2}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_87.geometry}
                material={materials.Green_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_88.geometry}
                material={materials.Green_L2E}
              />
            </group>
            <group rotation={[1.117, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_90.geometry}
                material={materials.Rubber_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_91.geometry}
                material={materials.Rubber_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_92.geometry}
                material={materials.Plastic_L2}
              />
            </group>
            <group rotation={[0.644, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_94.geometry}
                material={materials.M_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_95.geometry}
                material={materials.M_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_96.geometry}
                material={materials.M_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_97.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_98.geometry}
                material={materials.Plastic_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_99.geometry}
                material={materials.Plastic_Base}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_101.geometry}
                material={materials.Red_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_102.geometry}
                material={materials.Red_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_103.geometry}
                material={materials.Red_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_104.geometry}
                material={materials.Grey_L1}
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]} scale={0.27}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_110.geometry}
                material={materials.M_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_111.geometry}
                material={materials.M_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_112.geometry}
                material={materials.Plastic_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_113.geometry}
                material={materials.Plastic_Base}
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_115.geometry}
                material={materials.M_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_116.geometry}
                material={materials.Metall_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_117.geometry}
                material={materials.Metall_D1}
              />
            </group>
            <group rotation={[0, 0, -Math.PI / 2]} scale={0.27}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_121.geometry}
                material={materials.M_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_122.geometry}
                material={materials.M_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_123.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_124.geometry}
                material={materials.Plastic_Base}
              />
            </group>
            <group rotation={[-0.771, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_128.geometry}
                material={materials['Material.004']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_129.geometry}
                material={materials.Grey_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_130.geometry}
                material={materials.Plastic_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_131.geometry}
                material={materials.Rubber_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_132.geometry}
                material={materials.Plastic_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_133.geometry}
                material={materials.Plastic_Base}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_134.geometry}
                material={materials.Plastic_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_135.geometry}
                material={materials.Yellow_L1}
              />
            </group>
            <group rotation={[-1.329, 0, 0]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_137.geometry}
                material={materials['Material.011']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_138.geometry}
                material={materials['Material.012']}
              />
            </group>
            <group rotation={[Math.PI / 2, -Math.PI / 2, 0]} scale={[0.283, 0.313, 0.283]}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_144.geometry}
                material={materials.Metall_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_145.geometry}
                material={materials.Metall_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_146.geometry}
                material={materials.Metall_D2}
              />
            </group>
            <group rotation={[-2.586, 0, -Math.PI]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_148.geometry}
                material={materials.Metall_Basic}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_149.geometry}
                material={materials.Metall_D1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_150.geometry}
                material={materials.Metall_D2}
              />
            </group>
            <group scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_152.geometry}
                material={materials.CARBONE}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_153.geometry}
                material={materials['Material.001']}
              />
            </group>
            <group rotation={[0, -Math.PI / 2, 0]} scale={0}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_157.geometry}
                material={materials['Material.002']}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_158.geometry}
                material={materials['Material.001']}
              />
            </group>
            <group position={[0.017, 0.444, -0.003]} scale={0.283}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_164.geometry}
                material={materials.M_L1}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_165.geometry}
                material={materials.M_D2}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Object_166.geometry}
                material={materials['Material.010']}
              />
            </group>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_12.geometry}
              material={materials.Material}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_14.geometry}
              material={materials['Material.007']}
              position={[0, -0.009, -0.002]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_52.geometry}
              material={materials['Material.006']}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_54.geometry}
              material={materials['Material.014']}
              rotation={[1.117, 0, 0]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_68.geometry}
              material={materials.Metall_D2}
              rotation={[0.644, 0, 0]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_106.geometry}
              material={materials['Material.016']}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_108.geometry}
              material={materials['Material.017']}
              rotation={[-1.329, 0, 0]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_119.geometry}
              material={materials.Plastic_D1}
              rotation={[0, 0, -Math.PI / 2]}
              scale={0.27}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_126.geometry}
              material={materials['Material.008']}
              rotation={[-Math.PI / 2, 0, 0]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_140.geometry}
              material={materials.material}
              rotation={[0.266, 1.045, -2.015]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_142.geometry}
              material={materials.material}
              rotation={[-2.501, 1.206, 0.61]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_155.geometry}
              material={materials.Rubber_Basic}
              scale={[0.267, 0.283, 0.283]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_160.geometry}
              material={materials['Rubber_Tire.002']}
              rotation={[0, -Math.PI / 2, 0]}
              scale={0}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_162.geometry}
              material={materials['Material.007']}
              rotation={[-Math.PI / 2, 0, -Math.PI]}
              scale={0.283}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.Object_168.geometry}
              material={materials['Material.015']}
              rotation={[-0.113, 0, 0]}
              scale={0.283}
            />
          </group>
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Object_170.geometry}
            material={materials['Material.003']}
            position={[0, 1.017, -0.135]}
          />
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Object_172.geometry}
            material={materials['Material.017']}
            position={[0, 1.941, -0.135]}
          />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/dji_fvp_-_sdc_performance_edition.glb')
